import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/layout/layout';

const OchronaLakieru: React.FC = () => {
    return (
        <Layout
            title=" Ochrona lakieru - WrapTown - profesjonalne oklejanie
        samochodów"
            desc="Właśnie wyjechałeś nowym autem z salonu? Zabezpiecz lakier bezbarwną folią PPF!"
            url="https://wraptown.pl/ochrona-lakieru"
            type="website"
        >
            <main className="px-4 max-w-7xl mx-auto pt-20 pb-20 lg:px-6">
                <div className="mb-10">
                    <p>
                        <Link to="/">Home</Link> /{' '}
                        <Link to="/uslugi">Usługi</Link> / Ochrona lakieru
                    </p>
                </div>
                <div>
                    <h1 className="text-4xl  mb-6 font-extrabold text-black xl:text-5xl leading-tight xl:leading-snug">
                        Ochrona lakieru - WrapTown
                    </h1>
                    <p>...</p>
                </div>
            </main>
        </Layout>
    );
};

export default OchronaLakieru;
